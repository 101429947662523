<div class="container">
    <div class="row">
        <div class="d-none d-md-block col-md-3"></div>
        <div class="col-md-6 col-12 pb-5 text-center align-items-center">
            <a href="/">
                <img src="assets/logo/icon-and-text/PTFinder-IconAndText-Black.png"> 
            </a>
        </div>
        <div class="col-12 pb-5 text-center align-items-center">
            <h1>
                You can contact support at: 
                <br>
                <br>
                ptfinderapp@outlook.com
            </h1>
        </div>
    </div>
</div>
