<div class="container pt-5">

    <div class="row">
        <div class="col-12 d-none d-md-block pb-3">

        <footer>
            <!-- <div id="slack-signup" class="row pb-5">
                <div class="col-12 text-center pb-2">
                    <h3>Join the PTFinder Slack Community</h3>
                    <p><b>If you are a personal trainer</b>, come and join our Slack community!</p>
                </div>
                <div class="col-12 text-center">
                    <div id="mc_embed_signup">
                        <form class="form-inline" action="https://app.us10.list-manage.com/subscribe/post?u=1dc901056c850f12a899a5435&amp;id=68d4b76236" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" novalidate>
                            <div id="mc_embed_signup_scroll" class="form-group row w-100 align-items-center">
                                <div style="position: absolute; left: -5000px;" aria-hidden="true">
                                    <input type="text" name="b_1dc901056c850f12a899a5435_68d4b76236" tabindex="-1" value="">
                                </div>
                                <div class="col-12 text-center pb-2">
                                    <input class="form-control mr-2 w-50" type="email" value="" name="EMAIL" id="mce-EMAIL" placeholder="Enter your email address" required>
                                    
                                
                                    <input type="submit" value="Join" name="subscribe" id="mc-embedded-subscribe" class="btn btn-primary w-25">
                                </div>
                                <div class="col-12 text-center">
                                    <p>By entering your email you agree to join our mailing list so we can send you a Slack invite</p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div> -->

            <div class="row justify-content-end">
                <div class="col-4">
                <div class="col-12 pb-2">
                    <div class="row h-100">
                    <div class="col-12">
                        <a href="#home-top">
                        <img src="assets/logo/icon-and-text/PTFinder-IconAndText-Black.png"> 
                        </a>
                    </div>
                    </div>
                </div>
                <div class="col-12">
                    <p>© 2020 PTFinder</p>
                </div>
                <div class="col-12">
                    <a href="/termsandconditions" class="social">Terms and Conditions</a>
                </div>
                <div class="col-12">
                    <a href="/privacy" class="social">Privacy Policy</a>
                </div>
                </div>
                <div class="col-2"> </div>
                <div class="col-3">
                    <div class="row">

                        <div class="col-12 pb-2">
                            <h4>Download</h4>
                        </div>

                        <div class="col-12">
                            <div class="row">

                                <div class="col-12">
                                    <a [href]="appStoreLink">
                                        <img [src]="appStoreDownloadButtonLink" height="50px">
                                    </a>
                                </div>

                                <div class="col-12 pt-3">
                                    <!-- <a [href]="playStoreLink">
                                        <img [src]="playStoreDownloadButtonLink" height="44px" width="150px">
                                    </a> -->
                                    <img [src]="playStoreComingSoonButtonLink" height="50px" width="150px">
                                </div>

                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="col-3">
                    <div class="row">
                        <div class="col-12 pb-2">
                            <h4>Connect</h4>
                        </div>
                        <div class="col-12">
                            <p>Questions or feedback? 
                            <br>
                            We'd love to hear from you!
                            </p>
                        </div>
                        <div class="col-12">
                            <span style="font-size: 2rem;">
                                <a href="https://www.instagram.com/ptfinderapp" target="_blank" class="pr-3"><i class="fa fa-instagram social social-icon" aria-hidden="true"></i></a>
                                <a href="https://www.facebook.com/ptfinderapp" target="_blank" class="pr-3"><i class="fa fa-facebook social social-icon" aria-hidden="true"></i></a>
                                <a href="https://twitter.com/ptfinderapp" target="_blank" class="pr-3"><i class="fa fa-twitter social social-icon" aria-hidden="true"></i></a>
                                <a href="https://www.linkedin.com/company/ptfinderapp" target="_blank"><i class="fa fa-linkedin social social-icon" aria-hidden="true"></i></a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

        </div>

        <div class="col-12 d-block d-md-none pb-3">
    <footer>

        <!-- <div id="slack-signup-mobile" class="row pb-5">
        <div class="col-12 text-center">
            <div class="row h-100 pb-3">
            <div class="col-1"></div>
            <div class="col-10">
                <h3>Join the PTFinder Slack Community</h3>
                <p><b>If you are a personal trainer</b>, come and join our Slack community!</p>
            </div>
            <div class="col-1"></div>
            </div>
        </div>
        <div class="col-12 text-center">
            <div class="row h-100 pb-2">
            <div class="col-1"></div>
            <div class="col-10">
                <div id="mc_embed_signup">
                <form class="form" action="https://app.us10.list-manage.com/subscribe/post?u=1dc901056c850f12a899a5435&amp;id=68d4b76236" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                    <div id="mc_embed_signup_scroll row w-100">
                    <div class="form-group">
                        <input class="form-control w-100" type="email" value="" name="EMAIL" id="mce-EMAIL" placeholder="Enter your email address" required>
                         real people should not fill this in and expect good things - do not remove this or risk form bot signups
                        <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_1dc901056c850f12a899a5435_68d4b76236" tabindex="-1" value=""></div>
                    </div>
                    <div class="form-group">
                        <input type="submit" value="Join" name="subscribe" id="mc-embedded-subscribe" class="btn btn-primary w-100">
                    </div>
                    <p>By entering your email you agree to join our mailing list so we can send you a Slack invite</p>
                    </div>
                </form>
                </div>
            </div>
            <div class="col-1"></div>
            </div>
        </div>
    </div> -->

        <div class="row justify-content-end">
            <div class="col-12 pb-2 align-items-center text-center">
            <div class="row h-100 pb-3">
                <div class="col-1"></div>
                <div class="col-10">
                <a href="#home-top">
                    <img src="assets/logo/icon-and-text/PTFinder-IconAndText-Black.png"> 
                </a>
                </div>
                <div class="col-1"></div>
            </div>
            </div>
            <div class="col-12 pb-3">
            <div class="row">
                <div class="col-sm-6 col-12 my-auto text-center pb-4">
                <div class="row h-100">
                    <div class="col-12 pb-2">
                    <h4>Download</h4>
                    </div>
                    <div class="col-12 pb-2">
                            <div class="row">

                                <div class="col-12">
                                    <a [href]="appStoreLink">
                                        <img [src]="appStoreDownloadButtonLink" height="50px">
                                    </a>
                                </div>
                                
                                <div class="col-12 pt-3">
                                    <!-- <a [href]="playStoreLink">
                                        <img [src]="playStoreDownloadButtonLink" height="44px" width="150px">
                                    </a> -->
                                    <img [src]="playStoreComingSoonButtonLink" height="50px" width="150px">
                                </div>

                            </div>
                    </div>
                </div>
                </div>
                <div class="col-sm-6 col-12 text-center">
                <div class="row">
                    <div class="col-12 pb-2">
                        <h4>Connect</h4>
                    </div>
                    <div class="col-12">
                        <p>Questions or feedback? 
                        <br>
                        We'd love to hear from you!
                        </p>
                    </div>
                    <div class="col-12">
                        <a href="https://www.instagram.com/ptfinderapp" target="_blank" class="pr-3"><i class="fa fa-instagram social social-icon" aria-hidden="true"></i></a>
                        <a href="https://www.facebook.com/ptfinderapp" target="_blank" class="pr-3"><i class="fa fa-facebook social social-icon" aria-hidden="true"></i></a>
                        <a href="https://twitter.com/ptfinderapp" target="_blank" class="pr-3"><i class="fa fa-twitter social social-icon" aria-hidden="true"></i></a>
                        <a href="https://www.linkedin.com/company/ptfinderapp" target="_blank"><i class="fa fa-linkedin social social-icon" aria-hidden="true"></i></a>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div class="col-12 text-center">
                <p>© 2020 PTFinder | <a href="/termsandconditions" class="social">Terms and Conditions</a> | <a href="/privacy" class="social">Privacy Policy</a></p>
            </div>
        </div>
    </footer>
        </div>
    </div>
</div>