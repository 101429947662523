<div class="container"><div class="d-none d-md-block">
    <div class="col-12 section-padding">
        <div class="row">
            <div class="col-md-5">
                <div class="row h-100 align-items-center">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12 pb-4">
                                <img src="assets/logo/icon-and-text/PTFinder-IconAndText-Black.png"> 
                            </div>
                            <div class="col-12 pt-3 pb-3">
                                <h3>Searching for a personal trainer?</h3>
                                <br>
                                <p>Use PTFinder to discover and connect with personal trainers in your area, ensuring you know all the essential information to make the right decision. All for free.</p>
                            </div>
                            <div class="col-12">
                                <div class="d-lg-block d-md-none">
                                    <!-- <a class="mr-3" [href]="playStoreLink">
                                        <img [src]="playStoreDownloadButtonLink" height="50px">
                                    </a> -->

                                    <img class="mr-3" [src]="playStoreComingSoonButtonLink" height="50px" width="150px">
        
                                    <a [href]="appStoreLink">
                                        <img [src]="appStoreDownloadButtonLink" height="50px">
                                    </a>
                                </div>
                                <div class="d-md-block d-lg-none">
                                    <div class="row">
                                        <div class="col-12">
                                            <a [href]="appStoreLink">
                                                <img [src]="appStoreDownloadButtonLink" height="50px">
                                            </a>
                                        </div>
                                        <div class="col-12 pt-3">
                                            <!-- <a class="mr-3" [href]="playStoreLink">
                                                <img [src]="playStoreDownloadButtonLink" height="50px" width="150px">
                                            </a> -->

                                            <img [src]="playStoreComingSoonButtonLink" height="50px" width="150px">
                                        </div>
                                    </div>
                                </div>
    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-md-7 my-auto text-right">
            
                <img src="../assets/images/trainer-list.png">
            
            </div>
        </div>
    </div>
    
    <div class="col-12 section-padding">
        <div class="row h-100">
            
            <div class="col-md-7 my-auto text-left">
    
                <img src="../assets/images/trainer-profile-chat.png">
            
            </div>
    
            <div class="col-md-5 align-self-center">
                <h2 class="pb-2">Personal Trainers near you</h2>
                <p>Using our filters, you can define your requirements to discover, view and connect with local personal trainers that suit your needs.</p>
                <p>You've found a trainer, now what?</p>
                <p>You can use PTFinders inbuilt chat to contact the trainer, or, contact them directly using their up-to-date contact details on their profile.</p>
                <p><b>The best part, it's completely free to use.</b></p>
            </div>
        </div>
    </div>
    
    <div class="col-12 section-padding">
        <div class="row">
            
            <div class="d-none d-md-block col-12 my-auto text-center pb-5">
                <div class="row">
                    <div class="col-12">
                        <h2 class="pb-2">All the information, in one place</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p>The current process to find a personal trainer is, to put it bluntly, rubbish. Forget the fact you have no platform to find local personal trainers, but, where can you compare the prices of their packages and services? How do you know that you are not being over-charged?</p>
                        <p>Well, you don't and it's ridiculous; you shouldn't have to become a seasoned detective to ensure you're getting a fair price.</p>
                        <p>PTFinder collates everything into a single platform, so you can compare and contrast key information to make an informed decision in only a few minutes. Meaning, you can retire the trench coat and badge for a different search.</p>
                    </div>
                </div>
            </div>
            
            <div class="d-block d-md-none col-12 my-auto text-center pb-3">
                <div class="row">
                    <div class="col-12">
                        <h2 class="pb-2">All the information, in one place</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p>Have you ever searched for a personal trainer? The current process is, to put it bluntly, rubbish. Forget the fact you have no platform to find local personal trainers, but, where can you compare the prices of their packages and services? How do you know that you are not being over-charged?</p>
                        <p>Well, you don't and it's ridiculous; you shouldn't have to become a seasoned detective to ensure you're getting a fair price.</p>
                        <p>As a result, PTFinder collates everything into a single platform, so you can compare and contrast key information to make an informed decision in only a few minutes. Meaning, you can retire the trench coat and badge for a different search.</p>
                    </div>
                </div>
            </div>
    
            <div class="col-md-4 text-center">
                <img class="pb-4" src="../../assets/images/packages.png">
                <h4>Prices</h4>
                <p>See all the packages a trainer offers and their prices; including the number of sessions and session length you get with this package. Now, you can see if you are getting a fair price by comparing with other personal trainers.</p>
            </div>
    
            <div class="col-md-4 text-center">
                <img class="pb-4" src="../../assets/images/distance.png">
                <h4>Client Transformations</h4>
                <p>Look at a personal trainer's client transformations so you can see how they have helped others, giving you more confidence in your decision.</p>
            </div>
    
            <div class="col-md-4 text-center">
                <img class="pb-4" src="../../assets/images/about-them.png">
                <h4>About Them</h4>
                <p>You want to enjoy your fitness, right? That's why we let each trainers personality shine on their profile so you can find someone whom you will enjoy working with.</p>
            </div>
        </div>
    </div>
    
    <div class="col-12 pb-5">
        <div class="row h-100">
    
            <div class="col-md-5 align-self-center">
                
                <h2 class="pb-2">Showcase your goals</h2>

                <p>Hold on, how will a trainer know what you are trying to achieve? You don't want to repeat the same thing to every personal trainer.</p>
                <p>That's why PTFinder lets you show personal trainers who you are and what you are trying to achieve by setting your goals on your profile.</p>
                <p>As a result, anyone can view your profile to understand your ambitions, so you can hit the ground running from day 1 and stay on track.</p>
    
            </div>
            
            <div class="col-md-7 my-auto text-right">
    
                    <img src="../assets/images/client-profile.png">
            
            </div>
        </div>
    </div>
</div>

<div class="d-block d-md-none">
    <div class="col-12 pb-5">
        <div class="row">
            <div class="col-md-5">
                <div class="row h-100 align-items-center">
                    <div class="col-12 text-center pb-3">
                        <div class="row">
                            <div class="col-12 pb-4">
                                <img src="assets/logo/icon-and-text/PTFinder-IconAndText-Black.png"> 
                            </div>
                            <div class="col-12 pt-3">
                                <h3>Searching for a personal trainer?</h3>
                                <br>
                                <p>Use PTFinder to discover and connect with personal trainers in your area, ensuring you know all the essential information to make the right decision. All for free.</p>
                            </div>
                            <div class="col-12 pt-3">
                                <div class="d-sm-block d-none">
                                    <div class="row">
                                        <div class="col-sm-6 text-right">
                                            <!-- <a class="mr-3" [href]="playStoreLink">
                                                <img [src]="playStoreDownloadButtonLink" height="50px">
                                            </a> -->

                                            <img [src]="playStoreComingSoonButtonLink" height="50px" width="150px">
                                        </div>
                                        <div class="col-sm-6 text-left">
                                            <a [href]="appStoreLink">
                                                <img [src]="appStoreDownloadButtonLink" height="50px">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-block d-sm-none">
                                    <div class="row">
                                        <div class="col-12">
                                            <a [href]="appStoreLink">
                                                <img [src]="appStoreDownloadButtonLink" height="50px">
                                            </a>
                                        </div>
                                        <div class="col-12 pt-3">
                                            <!-- <a [href]="playStoreLink">
                                                <img [src]="playStoreDownloadButtonLink" height="44px" width="150px">
                                            </a> -->

                                            <img [src]="playStoreComingSoonButtonLink" height="50px" width="150px">
                                        </div>
                                    </div>
                                </div>
    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-12 pt-3 my-auto text-center">
            
                <img src="../assets/images/trainer-list.png">
            
            </div>
        </div>
    </div>
    
    <div class="col-12 pt-5 pb-5">
        <div class="row h-100">
    
            <div class="col-12 text-center pb-3">

                <h2 class="pb-2">Personal Trainers near you</h2>

                <p>Using our filters, you can define your requirements to discover, view and connect with local personal trainers that suit your needs.</p>
                <p>You've found a trainer, now what?</p>
                <p>You can use PTFinders inbuilt chat to contact the trainer, or, contact them directly using their up-to-date contact details on their profile.</p>
                <p><b>The best part, it's completely free to use.</b></p>
                
            </div>

            
            <div class="col-12 my-auto text-center">
            
                <img src="../assets/images/trainer-profile-chat.png">
            
            </div>

        </div>
    </div>
    
    <div class="col-12 pt-5 pb-5">
        <div class="row">
            
            <div class="col-12 my-auto text-center pb-3">
                <div class="row">
                    <div class="col-12">
                        <h2 class="pb-2">All the information, in one place</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p>The current process to find a personal trainer is, to put it bluntly, rubbish. Forget the fact you have no platform to find local personal trainers, but, where can you compare the prices of their packages and services? How do you know that you are not being over-charged?</p>
                        <p>Well, you don't and it's ridiculous; you shouldn't have to become a seasoned detective to ensure you're getting a fair price.</p>
                        <p>As a result, PTFinder collates everything into a single platform, so you can compare and contrast key information to make an informed decision in only a few minutes. Meaning, you can retire the trench coat and badge for a different search.</p>
                    </div>
                </div>
            </div>
    
            <div class="col-12 text-center pb-3">
                <img class="pb-4" src="../../assets/images/packages.png">
                <h4>Prices</h4>
                <p>See all the packages a trainer offers and their prices; including the number of sessions and session length you get with this package. Now, you can see if you are getting a fair price by comparing with other personal trainers.</p>
            </div>
    
            <div class="col-12 text-center pb-3">
                <img class="pb-4" src="../../assets/images/distance.png">
                <h4>Client Transformations</h4>
                <p>Look at a personal trainer's client transformations so you can see how they have helped others, giving you more confidence in your decision.</p>
            </div>
    
            <div class="col-12 text-center pb-3">
                <img class="pb-4" src="../../assets/images/about-them.png">
                <h4>About Them</h4>
                <p>You want to enjoy your fitness, right? That's why we let each trainers personality shine on their profile so you can find someone whom you will enjoy working with.</p>
            </div>
        </div>
    </div>

    <div class="col-12">
        <div class="row h-100">
    
            <div class="col-12 align-self-center text-center">
                
                <h2 class="pb-2">Showcase your goals</h2>

                <p>Hold on, how will a trainer know what you are trying to achieve? You don't want to repeat the same thing to every personal trainer.</p>
                <p>That's why PTFinder lets you show personal trainers who you are and what you are trying to achieve by setting your goals on your profile.</p>
                <p>As a result, anyone can view your profile to understand your ambitions, so you can hit the ground running from day 1 and stay on track.</p>

            </div>


            <div class="col-12 my-auto text-center pb-3">
            
                <img src="../assets/images/client-profile.png">
            
            </div>

        </div>
    </div>
</div>
</div>
