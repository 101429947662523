// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false
// };


export const environment = {
  production: false,
  apiKey: "AIzaSyC4tqxsTFyUaJSF7QnbjFSBmsZYrBSnyCQ",
  authDomain: "pthub-dev.firebaseapp.com",
  databaseURL: "https://pthub-dev.firebaseio.com",
  projectId: "pthub-dev",
  storageBucket: "pthub-dev.appspot.com",
  messagingSenderId: "875548587678",
  appId: "1:875548587678:web:c3458b309fda560c232d8f"
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
