import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './views/static/landing-page/landing-page.component';
import { PrivacyPolicyComponent } from './views/static/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './views/static/terms-and-conditions/terms-and-conditions.component';
import { ContactComponent } from './views/static/contact/contact.component';

import { SiteLayoutComponent } from './views/_layout/site-layout/site-layout.component';
import { SiteHeaderComponent } from './views/_layout/site-header/site-header.component';
import { SiteFooterComponent } from './views/_layout/site-footer/site-footer.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    ContactComponent,
    SiteLayoutComponent,
    SiteHeaderComponent,
    SiteFooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})

export class AppModule { }
