import { Component, OnInit } from '@angular/core';
import { Constants } from '../constants/Constants'

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  appStoreLink = Constants.APP_STORE_LINK;
  appStoreDownloadButtonLink = "../assets/download-buttons/app-store/app-store-button.svg";
  playStoreLink = Constants.PLAY_STORE_LINK;
  playStoreDownloadButtonLink = "../assets/download-buttons/play-store/google-play-badge.svg";
  playStoreComingSoonButtonLink = "../assets/download-buttons/play-store/coming-soon-google-play-badge.png";

  constructor() { }


  ngOnInit(): void {
  }

}
