import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SiteLayoutComponent } from './views/_layout/site-layout/site-layout.component';

import { LandingPageComponent } from './views/static/landing-page/landing-page.component';
import { PrivacyPolicyComponent } from './views/static/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './views/static/terms-and-conditions/terms-and-conditions.component';
import { ContactComponent } from './views/static/contact/contact.component';


const routes: Routes = [
  { 
    path: '', 
    component: SiteLayoutComponent,
    children: [
      { path: '', component: LandingPageComponent, pathMatch: 'full' },
      { path: 'privacy', component: PrivacyPolicyComponent },
      { path: 'termsandconditions', component: TermsAndConditionsComponent },
      { path: 'contact', component: ContactComponent }
    ]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }