import { Component, OnInit } from '@angular/core';
import { Constants } from '../../static/constants/Constants'

@Component({
  selector: 'site-footer',
  templateUrl: './site-footer.component.html',
  styleUrls: ['./site-footer.component.css']
})
export class SiteFooterComponent implements OnInit {

  appStoreLink = Constants.APP_STORE_LINK;
  appStoreDownloadButtonLink = "../../../assets/download-buttons/app-store/app-store-button.svg";
  playStoreLink = Constants.PLAY_STORE_LINK;
  playStoreDownloadButtonLink = "../../../assets/download-buttons/play-store/google-play-badge.svg";
  playStoreComingSoonButtonLink = "../../../assets/download-buttons/play-store/coming-soon-google-play-badge.png";

  constructor() { }

  ngOnInit(): void {
  }

}
